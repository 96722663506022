import React from "react";
import { Grid, Typography, TextField } from "@mui/material";
import { colors } from "../../lib/theme";

const GiftDeliveryDetails = ({ formData, handleChange }) => {
  // Calculate tomorrow's date
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const minDate = tomorrow.toISOString().split('T')[0];

  return (
    <>
      <Typography variant="h5" gutterBottom color={colors.navy}>
        Gift Delivery Details
      </Typography>
      <Typography variant="body1" gutterBottom color={colors.navy} sx={{ mb: 3 }}>
        Enter the recipient's information to send them a gift card via email.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom color={colors.navy}>
            Gift Recipient's First Name
          </Typography>
          <TextField
            name="recipientFirstName"
            required
            fullWidth
            value={formData.recipientFirstName}
            onChange={handleChange}
            sx={{ maxWidth: '100%' }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom color={colors.navy}>
            Recipient's Last Name
          </Typography>
          <TextField
            name="recipientLastName"
            required
            fullWidth
            value={formData.recipientLastName}
            onChange={handleChange}
            sx={{ maxWidth: '100%' }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom color={colors.navy}>
            Recipient's Email
          </Typography>
          <TextField
            name="recipientEmail"
            required
            fullWidth
            type="email"
            value={formData.recipientEmail}
            onChange={handleChange}
            sx={{ maxWidth: '100%' }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom color={colors.navy}>
            Send my gift on
          </Typography>
          <TextField
            name="sendGiftOn"
            required
            fullWidth
            type="date"
            InputLabelProps={{ shrink: true }}
            value={formData.sendGiftOn}
            onChange={handleChange}
            inputProps={{ min: minDate }}
            sx={{ maxWidth: '100%' }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default GiftDeliveryDetails;
